import React, {useEffect, useState} from 'react'
import './App.css';
import axios from 'axios'
import './createAccount.css'
import PhoneInput from 'react-phone-input-2'
import { Link } from "react-router-dom";
import Footer from './components/Footer.js'
import Header from './components/Header.js'
import { useNavigate } from 'react-router-dom';  
import Select from 'react-select';
import Cookies from 'js-cookie';



function Login() {
  const [roommatesNumb, setRoommatesNumb] = useState([])
  const [roommatesName, setRoommatesName] = useState([])
  const [userInfo, setUserInfo] = useState({})
  const [newNumb, setNewNumb] = useState("")
  const [newName, setNewName] = useState('')
  const [userNumber, setUsersNumber] = useState('')
  const [isUserInfo, setIsUserInfo] = useState(false)
  const [accountCreated, setAccountCreated] = useState(false)
  const electricProviders = [{value: "Eversource", label:"Eversource"}]
  const gasProviders = [{value: "National Grid", label:"National Grid"}]
  const [gasProvider, setGasProvider] = useState(null)
  const [electricProvider, setElectricProvider] = useState(null)
  const [isProviders, setIsProviders] = useState(false)
  const cookieSettings = {expires: 0.04, secure: true}
  const [acctStage, setAcctStage] = useState(0)

  const navigate = useNavigate()


  function createAcct(){

    const url = window.location.href
    console.log(url)
    const parsedUrl = new URL(url).searchParams.get('code');
    console.log("code: " + parsedUrl)
    if(parsedUrl != null){
      axios.post(process.env.REACT_APP_REQUEST_URL+'/getGAuthToken', 
        {code: parsedUrl, numbers: roommatesNumb, names:roommatesName, userNumber:userNumber, electricProvider:electricProvider.value, gasProvider: gasProvider.value}).then(function(res) {
        console.log(res.data)
        setUserInfo(res.data)
        Cookies.set('newName', res.data.data.names[0].displayName, cookieSettings)
        Cookies.set('roommatesNames', JSON.stringify(roommatesName), cookieSettings)
        Cookies.set('roommateNumbs', JSON.stringify(roommatesNumb), cookieSettings)

        // localStorage.setItem('info', JSON.stringify(res.data.data))  //set to cookie
        // console.log(localStorage.getItem('info'))
        navigate('/')
      })
    }
    setAccountCreated(true)
    return userInfo
  }

  const removeRoommate = (index) =>{
      let numb = [...roommatesNumb]
      numb.splice(index,1)
      setRoommatesNumb(numb)
      let name = [...roommatesName]
      name.splice(index,1)
      setRoommatesName(name)
  }

  const addRoommate = () => {
    let numb = [...roommatesNumb]
    numb.push(newNumb)
    setRoommatesNumb(numb)
    let name = [...roommatesName]
    name.push(newName)
    setRoommatesName(name)
    setNewNumb('1')
    setNewName('')
  }

  const RoommateInputs = () => {
    return (<div id="RoommateInput" className="container">
      
        {roommatesNumb.map((roommate, index) =>(
          <div className="d-flex m-3 justify-content-center flex-wrap" key={`roomate ${index}`} id="roommateInfo"> 
              <p className="" id="roommateName"> {`${roommatesName[index]}`}</p>
              <p className="" id="roommateNumber"> {`${roommate}`}</p>
              <button id="" className='' onClick={() => removeRoommate(index)}>Delete</button>
          </div>
        ))}
      </div>)
  }

  const change = (value) => {
      console.log(value)
      console.log(gasProvider)
      setElectricProvider(value)
  }

  const SelectProviders = () => {
    return (
      <div>

        <h4>Select Providers</h4>

        <div className='d-flex justify-content-center'>
          
          <div id = "provider" >
            Gas

            <Select 
              defaultValue={gasProvider}
              options={gasProviders} 
              onChange={setGasProvider}
            />

          </div>

          <div id = "provider">
            Electric

            <Select 
              defaultValue={electricProvider}
              options={electricProviders} 
              onChange={change}
            />
          </div>
        </div>

      </div>
    )
  }


  return (
    <div className="App">
      
      <Header/>

      <div id='body' className="container">

        <h2>Create Account</h2> 
              <div id="userInformation">           
                {(acctStage > 0) ? ( 
                  <div>
                    {(acctStage == 1) ? (
                      <div id="providerSelection">
                        <SelectProviders/>
                      </div>
                    ) : (
                      <div id="roommateInfoContainer"> 
                        Add roommates below
                        <RoommateInputs/>    
                      
                        <div className="d-flex flex-wrap m-3 justify-content-center" >
                          
                          <div className="d-flex justify-content-center" id="conName">
                            <div>
                              <div className="special-lable"> Name </div>
                                <input  
                                  type="text"
                                  id="nameInput"
                                  value={newName}
                                  placeholder='name'
                                  onChange={text => {setNewName(text.target.value)}}
                                />
                              </div>
                            </div>

                            <PhoneInput
                              id="numberInput" 
                              country={'us'}
                              name="input"
                              value={newNumb}
                              onChange={phone => {setNewNumb(phone)}}
                            />

                            <button id="addNumber" className="but" onClick={addRoommate}>Add</button>
                          </div>
                      </div>
                      )}
                  </div>
                  ) : ( 

                    <div id="RoommateInput" className="container">

                      <h4 id="userInputText">Enter Your Phone Number</h4>
                      <PhoneInput
                        id="numberInput" 
                        country={'us'}
                        name="input"
                        value={userNumber}
                        onChange={phone => {setUsersNumber(phone)}}
                      />
                    </div>)}

              </div>

            {acctStage == 2 ? (
                <div>
                  <button id="addUserNumber" className="m-3 btn btn-secondary" onClick={() => setAcctStage(acctStage-1)}>
                    Back
                  </button>
    
                  <button id="saveInfo" className='col m-3 btn btn-secondary' onClick={createAcct}> 
                    Create Account
                  </button>
                </div>
              ) : (
                <div>
                  <button id="addUserNumber" className="m-3 btn btn-secondary" onClick={() => setAcctStage(acctStage-1)}>
                    Back
                  </button>

                  <button id="addUserNumber" className="m-3 btn btn-secondary" onClick={() => setAcctStage(acctStage+1)}>
                    Next
                  </button>
                </div>
            )}


            <p>Please dont reload this page during the creation of your account</p>

      </div>

      <Footer id="footer"/>

    </div>
  );
}

export default Login;
