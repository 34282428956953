import React, {useEffect, useState} from 'react'
import './App.css';
import axios from 'axios'
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import UserComponent from './UserComponent.js';
import { useLocation } from 'react-router-dom';
import Footer from './components/Footer.js'
import Header from './components/Header.js'
import dotenv from 'dotenv/config';
import LoadingSpinner from "./components/loader";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';  


function App(props) {
  const [ user, setUser ] = useState({});
  const [ invalidUser, setInvalidUser ] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const cookieSettings =   {expires: 0.04, secure: true}
  const navigate = useNavigate()

  const login = useGoogleLogin({    
    onSuccess: (codeResponse) => {
      setUser(codeResponse)
    },
    onError: (error) => console.log('Login Failed:', error)
  });

  useEffect(
      () => {
          if (Object.keys(user).length > 0 ) {
            setIsLoading(true);
              axios
                  .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                      headers: {
                          Authorization: `Bearer ${user.access_token}`,
                          Accept: 'application/json'
                      },
                  })
                  .then((res) => {
                      if(res.data.email){
                        setIsLoading(false);
                        console.log(res.data);
                        setInvalidUser(false)
                        Cookies.set('id', res.data.id, cookieSettings);
                        Cookies.set('name', res.data.name, cookieSettings);
                      }
                  })
                  .catch((err) => console.log(err));
          }
      },
      [user]
  );

  setInterval(() => {
    navigate('/')
  }, 601000)

// log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    // setProfile({});
    //setNewUser(null)
    window.history.replaceState({}, document.title)
    Cookies.remove('id', cookieSettings)
    Cookies.remove('name', cookieSettings)
    Cookies.remove('roommatesNames', cookieSettings)
    Cookies.remove('roommateNumbs', cookieSettings)
    Cookies.remove('newName', cookieSettings)
    navigate('/')
  };

  const gmailAuth = () => {
    setIsLoading(true);
    axios.get(process.env.REACT_APP_REQUEST_URL+'/getGoogleAuthLink').then(function(response) {
      setIsLoading(false)
      console.log(response.data);
       window.open(response.data, "_self")
    })
  }   

  const noUser = (data) => {
    setInvalidUser(true)
  }


  return (
    <div className="App">

      <Header/>

      <div id="holder">
        {isLoading ? <LoadingSpinner /> : null}
          {(Cookies.get('newName') == undefined) &&  (Cookies.get('id') == undefined) ? (<h1 id="welcomeText">Welcome to Utility Reminders</h1>) : <div></div>}
          {invalidUser ? (<h3>Email not registered, please create an account with that email first</h3>) : <div></div>}
        
          {(Cookies.get('newName') != undefined) ? 
            (<div>
              <UserComponent noUser={noUser} logOut={logOut} newUser={true}/>
            </div>) 
            :
            (Cookies.get('id') != undefined)?
              (<div>
                <UserComponent noUser={noUser} logOut={logOut} newUser={false}/>

              </div>)
              :  
                (<div id="signInButtons" className='container'>
                  <div className="d-flex justify-content-around">
                    <button className='btn btn-primary' id="createButton" onClick={gmailAuth}>Create Account</button>
                    <button className='btn btn-primary' id="loginButton" onClick={login}>Login</button>
                  </div>
                </div>
            )} 

           {(Cookies.get('newName') != undefined) &&  (Cookies.get('id') == undefined) ? (<p>For roommates by roommates</p> ) : (null)}

      </div>

      <Footer id="footer"/>

    </div>
  );
}

export default App;
