import React from "react";
import "./loader.css";

export default function LoadingSpinner() {
  return (
    <div className="spinner-container">
      <div className="load-backgound"> 
        <div className="loading-spinner"></div>
      </div>  
    </div>
  );
}