import React, {useEffect, useState} from 'react'
import './App.css';
import axios from 'axios'
import './UserComponent.css'
import PhoneInput from 'react-phone-input-2'
import dotenv from 'dotenv/config';
import LoadingSpinner from "./components/loader";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';  



const UserComponent = (props) => {
  const [roommatesNumbs, setRoommatesNumbs] = useState([]) //cookie
  const [roommatesNames, setRoommatesNames] = useState([]) //cookie
  const [newNumb, setNewNumb] = useState('')
  const [newName, setNewName] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const [validUser, setValidUser] = useState(true)
  const cookieSettings =  {expires: 0.04, secure: true}
  const navigate = useNavigate()

  useEffect(() =>{

    if(Cookies.get('roommateNumbs') == undefined) {
      setIsLoading(true);
      // axios.get(process.env.REACT_APP_REQUEST_URL+'/getUserInfo/'+props.userID).then(function(response) {
        axios.get(process.env.REACT_APP_REQUEST_URL+'/getUserInfo/'+ Cookies.get('id')).then(function(response) {

        setIsLoading(false);
        console.log(response)
          if(response.data==""){
            props.noUser({})
            Cookies.remove('id', cookieSettings);
            Cookies.remove('name', cookieSettings);
            navigate('/')
          } else if(response.data.roommatesNumbs != null) {
            setValidUser(true)
            console.log(response.data)
            const numbs = [...response.data.roommatesNumbs]             
            if(Cookies.get('roommatesNumbs' == undefined)) Cookies.set('roommateNumbs', JSON.stringify(numbs), cookieSettings)
            setRoommatesNumbs(numbs) //dont need
            const names = [...response.data.roommatesNames]
            if(Cookies.get('roommatesNames' == undefined)) Cookies.set('roommatesNames', JSON.stringify(names), cookieSettings)
            setRoommatesNames(names) //dont need
       
          }
      })
    }
  },[])

  const deleteAccount = async () => {
    // axios.post(process.env.REACT_APP_REQUEST_URL+'/test/'+props.userID).then(function(response) {
    axios.post(process.env.REACT_APP_REQUEST_URL+'/test/'+Cookies.get('id')).then(function(response) {

    })
    props.logOut()
  }

  function removeRoommate(index) {
    let currentName = Cookies.get('roommatesNumbs')
    let numb = [...currentName]
    numb.splice(index,1)
    // setRoommatesNumbs(numb)
    let currentNumbs = Cookies.get('roommatesNumbs')
    let name = [...currentNumbs]
    name.splice(index,1)
    // setRoommatesNames(name)
    Cookies.set('roommateNumbs', JSON.stringify(numb), cookieSettings)
    Cookies.set('roommatesNames', JSON.stringify(name), cookieSettings)

    // localStorage.setItem('roommatesNumbs', JSON.stringify(numb))
    // localStorage.setItem('roommatesNames', JSON.stringify(name))
  }

  useEffect(() => {
    // if(!props.newUser)  axios.post(process.env.REACT_APP_REQUEST_URL+'/roommateUpdate/'+props.userID, {roommatesNumbs: roommatesNumbs, roommatesNames: roommatesNames})
    if(!props.newUser)  axios.post(process.env.REACT_APP_REQUEST_URL+'/roommateUpdate/'+ Cookies.get('id'), {roommatesNumbs: roommatesNumbs, roommatesNames: roommatesNames})

  }, [roommatesNumbs]);

  const addNumber = () => {
    let currentName = Cookies.get('roommatesNumbs')
    let numb = [...currentName]
    numb.push(newNumb)
    setRoommatesNumbs(numb)
    let currentNumbs = Cookies.get('roommatesNumbs')
    let name = [...currentNumbs]
    name.push(newName)
    setRoommatesNames(name)
    setNewNumb('')
    setNewName('')
    Cookies.set('roommateNumbs', JSON.stringify(numb), cookieSettings)
    Cookies.set('roommatesNames', JSON.stringify(name), cookieSettings)

    // localStorage.setItem('roommatesNumbs', JSON.stringify(numb)) //update cookie
    // localStorage.setItem('roommatesNames', JSON.stringify(name)) //update cookie
    setNewNumb("+1")
  }

  return (
    <div>
      { validUser == true ? ( 
          <>
         {isLoading ? <LoadingSpinner /> : null}
           <h2 id="welcomePersonal">Welcome {Cookies.get('name') || Cookies.get('newName') || ''}</h2> 
          <div className="container d-flex align-items-center" id="phoneNumbers">
            Current Roommates
             {roommatesNumbs != null ? (
              roommatesNumbs.map((roommate, index) => (
                <div className="d-flex flex-wrap m-3 justify-content-center" key={`roomate ${index}`} id="roommateInfo"> 
                    <p className="d-flex align-items-center" id="roommateName"> {`: ${roommatesNames[index]}`}</p>
                    <p className="d-flex align-items-center" id="roommateNumber"> {`: ${roommate}`}</p>
                    <button id="" className="but" onClick={() => removeRoommate(index)}>Delete</button>
                </div>
              ))
              ) : null}
              <div className="d-flex flex-wrap  m-3 justify-content-center" id="inputer">

                <div id="nameInputContainer">
                  <div className="special-lable"> Name</div>
                  <input  
                    type="text"
                    id="nameInput"
                    value={newName}
                    placeholder='name'
                    onChange={text => {setNewName(text.target.value)}}
                  />
                </div>

                <PhoneInput
                  placeholder="Enter phone number"
                  id="numberInput" 
                  country={'us'}
                  value={newNumb}
                  onChange={phone => {setNewNumb(phone)}}
                />

                <button id="addNumber" className="but" onClick={addNumber}>Add</button>

              </div>
          </div>
          <button className="but"  onClick={() => (props.logOut())}>Log Out</button>

          <button className="but" id="deleteAcct"onClick={() => {deleteAccount()}}> Delete Account</button>   

        </> ) : (<div> </div>) }

    </div>
  );
}

export default UserComponent;